@import '../../../../vendor/metronic/theme/sass/global/_variables'; // global metronic framework variables

.dashboard-stat2-clickable {
    display:block;

    &:hover .dashboard-stat2 {
        transition: all 0.3s;
    }
}

@each $name, $colors in $component-colors {
    .dashboard-stat2-clickable {
        &:hover {
            .dashboard-stat2.#{$name} {
                background-color: map-get($colors, base);
            }
            
            .dashboard-stat2.#{$name},
            .dashboard-stat2.#{$name} small,
            .dashboard-stat2.#{$name} .status-title,
            .dashboard-stat2.#{$name} .status-number,
            .font-#{$name} {
                color: map-get($colors, font) !important;
            }
        }
    }
}