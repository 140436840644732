.taglist {
    margin: 0.5em 0;

    ul {
        display: inline;
        margin: 0;
        padding: 0;
    }
}

.taglist-label {
    font-weight: bold;
}

.taglist-tag {
    list-style-type: none;
    display: inline-block;
    margin: 0.25em 0;
}

.taglist-link {

}