.alert {
    padding-left: 2em;
    padding-right: 2em;
}

.break-all {
    word-break: break-all;
}

.break-word {
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.danger, a > i.fa.danger {
    color: red;
}

.footer {
    margin-bottom: 5px;
}

.nowrap {
    white-space: nowrap;
}

.radio-list > label.radio-inline:first-child {
    padding-left: 20px;
}

.sponsors {
    color: #bbb;
    margin-bottom: 10px;
}

.sweet-alert .sa-icon,
.sweet-alert .sa-icon.sa-success .sa-placeholder {
    border-radius: 50% !important;
}

.table-fixed {
    table-layout: fixed;
}

.table-striped {
    > tbody > tr:nth-of-type(even) {
        background-color: lightgrey;
    }
}

.action-plan {
    border: 1px solid lightgrey;
    background-color: #f8f8f8;
    margin: 10px 0 20px 0;
    padding: 15px;
    white-space: pre;
}

@import 'custom/_dashboard-stat2-clickable';
@import 'custom/_validation-errors';
@import 'custom/_grouped-cell-data';
@import 'custom/_thumbnail';
@import 'custom/_taglist';
@import 'global/components/tabs';
