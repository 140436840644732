.thumbnail {
    float: right;
    margin: 0 0 1em 1em;

    img.normal {
        width: 170px;
    }

    img.small {
        width: 100px;
    }
}